<template>
  <div>
    <el-carousel indicator-position="none" :interval="8000">
      <el-carousel-item
        class="carousel-item"
        v-for="(item, index) in listContainer"
        :key="index"
      >
        <div
          style="
            width: 2px;
            height: 860px;
            position: absolute;
            left: 682px;
            top: 60px;
            background-color: #999;
          "
        ></div>
        <div class="item" v-for="i in item">
          <div class="titleBox">
            <div class="title">
              {{ i.name }}
            </div>
            <div class="price">
              <span>（{{ i.weight }}克/份）</span>
            </div>
            <div class="price" v-if="i.price">
              <span>￥{{ i.price }}元</span>
            </div>
            <div class="canci" v-if="i.week || i.meal_name">
              <span v-if="i.week">{{ i.week }}</span>
              <span v-if="i.meal_name" style="margin-left: 5px">{{
                i.meal_name
              }}</span>
            </div>
          </div>
          <div class="contentBox">
            <el-image
              class="img"
              style="
                width: 500px;
                height: 210px;
                border-radius: 10px;
                margin-right: 20px;
              "
              :src="i.food_images || defaultImg"
              :preview-src-list="[i.food_images || defaultImg]"
            ></el-image>
            <!-- <img
              class="img"
              :src="i.food_images || defaultImg"
              style="
                width: 300px;
                height: 210px;
                border-radius: 10px;
                margin-right: 20px;
              "
            /> -->
            <div class="content">
              <div class="spec" v-if="i.yellow[0] != ''" style="color: #f5bc1a">
                <div style="width: 40px;height: 40px;border-radius: 50%;background-color: #f5bc1a;display: inline-block;vertical-align: middle;margin-right: 10px;margin-bottom: 10px;"></div><span style="font-size: 28px;">黄灯食物</span>
                <div>
                  <span class="desc" v-for="(k, index) in i.yellow" >{{ k }}{{ index < i.yellow.length - 1 ? '、' : '' }}</span>
                </div>
              </div>
              <div class="spec" v-if="i.green[0] != ''" style="color: #4cca79">
                <div style="width: 40px;height: 40px;border-radius: 50%;background-color: #4cca79;display: inline-block;vertical-align: middle;margin-right: 10px;margin-bottom: 10px;"></div><span style="font-size: 28px;">绿灯食物</span>
                
                <div>
                  <span class="desc" v-for="(k, index) in i.green" >{{ k }}{{ index < i.green.length - 1 ? '、' : '' }}</span>
                </div>
              </div>
              <div class="spec" v-if="i.red[0] != ''" style="color: #d81e06">
                <div style="width: 40px;height: 40px;border-radius: 50%;background-color: #d81e06;display: inline-block; vertical-align: middle; margin-right: 10px;margin-bottom: 10px;"></div><span style="font-size: 28px;">红灯食物</span>
                
                <div>
                  <span class="desc" v-for="(k, index) in i.red" >{{ k }}{{ index < i.red.length - 1 ? '、' : '' }}</span>
                </div>
                
              </div>
              <div class="jinzhi" v-if="i.specialCareful != ''">
                <img src="../assets/image/jinzhi.png" /><span><span style="font-weight: bolder;">慎用人群：</span>{{
                  i.specialCareful || "无"
                }}</span>
              </div>
              <div class="guomin" v-if="i.allergy != ''">
                <img src="../assets/image/guomin.png" />
                <span style="font-weight: bolder;">过敏源：</span>
                <span>{{ i.allergy || "无" }}</span>
              </div>
              <!--  v-if="i.recommend_tags != ''" -->
              <!-- <div class="tujian">
                <img src="../assets/image/tujian.png" />
                推荐：<span>{{ i.recommend_tags || "无" }}</span>
              </div> -->
              <div class="ladu" v-if="i.hot != '无'">
                辣度
                <img
                  v-for="i in levelMap[i.hot]"
                  style="width: 20px; vertical-align: middle;"
                  src="../assets/image/ladu.png"
                  alt=""
                  :key="i"
                />
              </div>
            </div>
          </div>
          <div class="cartTitle">
            <img
              src="../assets/image/tree.png"
              style="vertical-align: middle; width: 45px; margin-bottom: 6px"
            />
            <span style="margin: 0 10px">营养成分表</span>
            <span style="font-size: 18px">Nutrients Label</span>
          </div>
          <div class="cartTitle2">
            菜品图片仅供参考
          </div>
          <div class="chartBox">
            <Chart
              style="width: 59%; height: 550px"
              :option="setTemperature5(i.food_info)"
            ></Chart>
            <div class="chartTable">
              <div class="tableTitle">
                <span>营养素</span><span>每100克</span>
              </div>

              <div class="tableItem">
                <div>蛋白质(克)</div>
                <div>{{ i.food_detail.protein }}</div>
              </div>
              <div class="tableItem">
                <div>碳水化合物(克)</div>
                <div>{{ i.food_detail.protein }}</div>
              </div>
              <div class="tableItem">
                <div>脂肪(克)</div>
                <div>{{ i.food_detail.fat }}</div>
              </div>
              <div class="tableItem">
                <div>钠(mg)</div>
                <div>{{ i.food_detail.na }}</div>
              </div>
              <div class="tableItem">
                <div>钙(mg)</div>
                <div>{{ i.food_detail.ca }}</div>
              </div>
              <div class="daka">
                <div>能量(大卡)</div>
                <div>{{ i.food_detail.energy_kcal }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import Chart from "../components/chart.vue";
import { getFoodAnalysis } from "../api/api";
export default {
  data() {
    return {
      list: [],
      listContainer: [],
      food_info: [],
      ChartTemperature5: {}, //热量配置项
      levelMap: {
        一级: 1,
        二级: 2,
        三级: 3,
        四级: 4,
        五级: 5,
      },
      defaultImg:"https://shuwangysa.oss-cn-hangzhou.aliyuncs.com/imgs/nofood.png",
    };
  },
  components: {
    Chart,
  },
  methods: {
    setTemperature5(item) {
      return {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          x: "center",
          y: "500",
          data: item,
          textStyle: {
            color: "white",
            fontSize: 20,
          },
        },
        calculable: true,
        series: [
          {
            name: "食材占比",
            type: "pie",
            radius: [25, 80],
            center: ["50%", "50%"],
            roseType: "radius",
            data: item,
            label: {
              show: true,
              position: "outside",
              formatter: "{d}%",
              fontSize: 20,
              color: "white",
            },
          },
        ],
      };
    },

    async getgetFoodAnalysisData() {
      const res = await getFoodAnalysis();
      this.list = res.data.data.map((item) => {
        if (!item.food_images?.includes("http") && item.food_images != '') {
          return {
            ...item,
            food_images:
              "https://shuwangysa.oss-cn-hangzhou.aliyuncs.com" +
              item.food_images,
            food_info: item.food_info.map((foodItem) => ({
              ...foodItem,
              value: foodItem.percent,
            })),
          };
        } else {
          return {
            ...item,
            food_info: item.food_info.map((foodItem) => ({
              ...foodItem,
              value: foodItem.percent,
            })),
          };
        }
      });
      for (let i = 0; i < this.list.length; i += 2) {
        this.listContainer.push(this.list.slice(i, i + 2));
      }
      console.log(this.listContainer, "getFoodAnalysis");
    },
  },
  mounted() {
    this.getgetFoodAnalysisData();
  },
  watch: {},
};
</script>

<style scoped>
::v-deep .el-carousel__container {
  height: 950px;
}
</style>
<style lang="scss" scoped>
.carousel-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-around;
  .item {
    width: 47%;
    // border-right: 1px solid #6e7a8b;
    padding-right: 10px;
    padding-left: 25px;
    position: relative;
    .titleBox {
      position: relative;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      font-weight: bolder;
      font-size: 19px;
      .title {
        color: white;
        font-size: 30px;
        margin-bottom: 10px;
        margin-right: 10px;
      }
      .price {
        color: #b3b2c2;
        span {
          color: #bfbb71;
          margin-right: 10px;
          font-size: 18px;
        }
      }
      .canci {
        height: 40px;
        padding: 0px 10px;
        background-color: rgba(255, 255, 159, 0.8);
        position: absolute;
        right: 20px;
        top: 0px;
        border-radius: 9px;
        line-height: 40px;
        text-align: center;
        color: #333;
      }
    }
    .contentBox {
      display: flex;
      .content {
        // color: #b3b2c2;
        color: white;
        font-size: 19px;
        position: relative;
        width: 100%;
        margin-right: 10px;
        background-color: rgba(255, 246, 143, 0.1);
        border-radius: 9px;
        padding-left: 10px;
        padding-top: 10px;
        .spec {
          margin: 10px 0;
          img {
            width: 20px;
            vertical-align: middle;
            margin-right: 4px;
          }
          .desc{
            color: white;       
          }
          .desc:nth-child(1) {
            margin-left: 50px;
          }
        }
        .jinzhi,
        .guomin,
        .tujian{
          color: #999;
          font-size: 17px;
          margin:15px 0;
          margin-left: 20px;
          display: flex;
          align-items: center;
          img{
            width: 20px;
            margin-right: 10px;
          }
        }
        .ladu {
          padding: 5px;
          width: 255px;
          display: flex;
          position: absolute;
          bottom: 0px;
          left: -285px;
          color: #333;
          font-size: 15px;
          background-color: rgba(255,255,255,.4);
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
        }
      }
    }
    .cartTitle {
      position: absolute;
      top: 310px;
      color: #999;
      font-size: 30px;
      font-weight: bolder;
      padding: 0px 5px;
      border-bottom: 2px solid #999;
      width: 91%;
      left: 25px;
    }
    .cartTitle2 {
      position: absolute;
      color: #999;
      font-size: 16px;
      bottom: -40px;
      left: 30px;
    }

    .chartBox {
      display: flex;
      justify-content: space-around;
      .chartTable {
        width: 32%;
        height: 320px;
        background-color: rgba(44, 86, 92, 0.5);
        margin-top: 140px;
        border-radius: 15px;
        margin-right: 15px;
        color: white;
        padding: 20px 20px;
        font-size: 20px;
        .tableTitle {
          font-size: 26px;
          font-weight: bolder;
          border-bottom: 2px solid white;
          display: flex;
          padding-bottom: 15px;
          justify-content: space-between;
        }
        .tableItem {
          display: flex;
          justify-content: space-between;
          margin: 15px 0;
        }
        .daka {
          display: flex;
          justify-content: space-between;
          margin: 15px 0;
          border-bottom: 2px solid white;
          padding-bottom: 20px;
        }
      }
    }
  }
}
</style>
