<template>
  <!-- 全屏容器 -->
  <v-scale-screen width="1920" height="1080" fullScreen>
    <div class="container">      
      <Time style="position: absolute;top: 40px;left: 150px;"></Time>
      <weather  style="position: absolute;top: 50px;right: 150px;z-index: 99;"></weather>
      <!-- 标题栏部分 -->
      <AiHeader ref="mychild" :analysis="true"></AiHeader>
      <div class="box-container">
        <aside>
          <!-- 门店信息 -->
          <div data-title="门店信息 " class="box-left-one">
            <div class="logo">
              <img :src="shop_img" />
              <div class="bg">
                <div class="phone" style="margin: 5px 0; font-size: 24px">
                  <span>{{ shop_name }}</span>
                </div>
                <div class="phone">
                  <span>负 责 人 ：</span>
                  <span>{{ shop_owner }}</span>
                </div>
                <div class="phone">
                  <span>联系电话：</span>
                  <span>{{ shop_phone }}</span>
                </div>
                <div class="phone">
                  <span>餐厅面积：</span>
                  <span>{{ area }}</span>
                </div>
                <div class="phone">
                  <span>就餐人数：</span>
                  <span>{{ users_amount }}</span>
                </div>
              </div>
            </div>
            <!-- <Chart style="width: 100%" :option="ChartTemperature1"></Chart> -->
          </div>
          <div data-title="主食热量排名" class="box-left-two">
            <Chart
              style="width: 100%; height: 250px"
              :option="ChartTemperature1"
            ></Chart>
          </div>
          <!-- 蛋白质含量高的食物 -->
          <div data-title="高蛋白食物排名" class="box-left-three">           
            <Chart
              style="width: 100%; height: 300px"
              :option="ChartTemperature3"
            ></Chart>
          </div>
        </aside>

        <section>
          <!--每周菜品营养分析 -->
          <div
            data-title="每周菜品营养分析"
            class="center-top"
            style="height: 92%"
          >
            <div style="width: 100%">
              <foodAnalysis></foodAnalysis>
            </div>
          </div>
        </section>
      </div>
    </div>
  </v-scale-screen>
</template>
<script>
import Chart from "../components/chart.vue";
import AiHeader from "../components/aiHeader.vue";
import Swiper from "../components/swiper.vue";
import foodAnalysis from "../components/foodAnalysis.vue";
import Time from "../components/time.vue";
import weather from "..//components/weather.vue";
import {} from "../api/api";
// 引入混入
import { myMixin } from "../common/common.js";

import * as echarts from "echarts";

export default {
  data() {
    return {
      ChartTemperature1: {}, //主食图表配置项
      chartList1: [],
      start: 0,
      end: 60,
      ChartTemperature2: {}, //人体每日膳食配置项
      ChartTemperature3: {}, //蛋白质含量配置项
      ChartTemperature4: {}, //VC配置项
      list: [],
      yundongList: [],
    };
  },
  mixins: [myMixin],
  methods: {
    setTemperature() {
      this.ChartTemperature1 = {
        backgroundColor: "#0f375f",
        grid: {
          top: "25%",
          bottom: "10%", //也可设置left和right设置距离来控制图表的大小
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,              
            },
          },
        },
        legend: {
          data: ["碳水化合物", "热量"],
          top: "15%",
          textStyle: {
            color: "#ffffff",
            fontSize: 16,
          },
        },
        xAxis: {
          data: this.chartList1,
          axisLine: {
            show: true, //隐藏X轴轴线
            lineStyle: {
              color: "#01FCE3",
            },
          },
          axisTick: {
            show: true, //隐藏X轴刻度
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#ebf8ac", //X轴文字颜色
            },
          },
        },
        yAxis: [
          {
            type: "value",
            // name: "亿元",
            nameTextStyle: {
              color: "#ebf8ac",
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: true,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#FFFFFF",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#ebf8ac",
              },
            },
          },
          {
            type: "value",
            // name: "同比",
            nameTextStyle: {
              color: "#ebf8ac",
            },
            position: "right",
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              formatter: "{value} ", //右侧Y轴文字显示
              textStyle: {
                color: "#ebf8ac",
              },
            },
          },
          {
            type: "value",
            gridIndex: 0,
            min: 50,
            max: 100,
            splitNumber: 8,
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ["rgba(250,250,250,0.0)", "rgba(250,250,250,0.05)"],
              },
            },
          },
        ],
        series: [
          {
            name: "热量",
            type: "line",
            yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
            smooth: true, //平滑曲线显示
            showAllSymbol: true, //显示所有图形。
            symbol: "circle", //标记的图形为实心圆
            symbolSize: 10, //标记的大小
            itemStyle: {
              //折线拐点标志的样式
              color: "#058cff",
            },
            lineStyle: {
              color: "#058cff",
            },
            areaStyle: {
              color: "rgba(5,140,255, 0.2)",
            },
            data: [4.2, 3.8, 4.8, 3.5, 2.9, 2.8, 3, 5],
          },
          {
            name: "碳水化合物",
            type: "bar",
            barWidth: 15,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#00FFE3",
                  },
                  {
                    offset: 1,
                    color: "#4693EC",
                  },
                ]),
              },
            },
            data: [4.2, 3.8, 4.8, 3.5, 2.9, 2.8, 3, 5],
          },
        ],
        dataZoom: [
          //给x轴设置滚动条
          {
            type: "slider", //slider表示有滑动块的，inside表示内置的
            start: this.start, //默认为0  可设置滚动条从在后进行展示
            end: this.end, //默认为100
            show: false,
            xAxisIndex: [0],
            // handleSize: 0, //滑动条的 左右2个滑动条的大小
            // height: 12, //组件高度
            // left: "10%", //左边的距离
            // right: "10%", //右边的距离
            // bottom: -2, //右边的距离
            // borderColor: "#f68b8f", //两边未选中的滑动条区域的颜色
            // fillerColor: "#ff5e70", // 两边选中的滑动条区域的颜色
            // backgroundColor: "#eee", //两边未选中的滑动条区域的颜色
            // showDataShadow: false, //是否显示数据阴影 默认auto
            // showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
            // realtime: true, //是否实时更新
            // filterMode: "filter",
            // handleStyle: {
            //   borderRadius: "20",
            // },
          },
          //下面这个属性是里面拖到
          {
            type: "inside",
            show: true,
            xAxisIndex: [0], // 与上面xAxisIndex对应
            start: 0, //默认为1
            end: 100, //默认为100
            moveOnMouseWheel: false, // 鼠标滚轮时是否滚动
            preventDefaultMouseMove: false, // 是否阻止默认的鼠标移动事件
          },
        ],
      };
    },
    setTemperature2() {
      this.ChartTemperature2 = {
        series: [
          {
            type: "treemap",
            name: "营养分析",
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  formatter: "{b}",
                  textStyle: {
                    fontSize: 18, // 设置字体大小为 18
                    fontWeight: 600,
                  },
                },
                borderWidth: 3,
                borderColor: "transparent",
              },
              emphasis: {
                label: {
                  show: true,
                },
              },
            },
            leafDepth: 4,
            breadcrumb: false,
            data: [
              {
                // name: "全谷物和杂豆",
                value: 10,
                itemStyle: {
                  color: "#0b9c6b",
                  borderRadius: 15,
                },
                children: [
                  {
                    name: "微量元素",
                    value: 4,
                    itemStyle: {
                      color: "#f5a623",
                      borderRadius: 15,
                    },
                    children: [
                      {
                        name: "糖",
                        value: 2,
                        itemStyle: {
                          color: "#dbe369",
                          borderRadius: 15,
                        },
                      },
                      {
                        name: "油",
                        value: 2,
                        itemStyle: {
                          color: "#dbe369",
                          borderRadius: 15,
                        },
                      },
                      {
                        name: "盐",
                        value: 2,
                        itemStyle: {
                          color: "#dbe369",
                          borderRadius: 15,
                        },
                      },
                    ],
                  },
                  {
                    name: "全谷物和杂豆",
                    value: 14,
                    itemStyle: {
                      color: "#f5a623",
                      borderRadius: 15,
                    },
                  },
                  {
                    name: "薯类",
                    value: 2,
                    itemStyle: {
                      color: "#f5a623",
                      borderRadius: 15,
                    },
                  },
                ],
              },
              {
                name: "蛋白质",
                value: 20,
                children: [
                  {
                    // name: "VC",
                    value: 20,
                    opacity: 0,
                    children: [
                      {
                        // name: "VC",
                        value: 20,
                        itemStyle: {
                          color: "#0fa68a",
                          borderRadius: 15,
                        },
                        children: [
                          {
                            name: "蔬菜",
                            value: 8,
                            itemStyle: {
                              color: "#0fa68a",
                              borderRadius: 15,
                            },
                          },
                          {
                            name: "水果",
                            value: 6,
                            itemStyle: {
                              color: "#0fa68a",
                              borderRadius: 15,
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    // name: "蛋白质",
                    value: 20,
                    opacity: 0,
                    children: [
                      {
                        // name: "奶",
                        value: 50,
                        itemStyle: {
                          color: "#0fa68a",
                          borderRadius: 15,
                        },
                        children: [
                          {
                            name: "坚果",
                            value: 10,
                            itemStyle: {
                              color: "#0b9c6b",
                              borderRadius: 15,
                            },
                          },
                          {
                            name: "肉",
                            value: 10,
                            itemStyle: {
                              color: "#0b9c6b",
                              borderRadius: 15,
                            },
                          },
                          {
                            name: "豆",
                            value: 20,
                            itemStyle: {
                              color: "#0b9c6b",
                              borderRadius: 15,
                            },
                          },
                          {
                            name: "水产",
                            value: 10,
                            itemStyle: {
                              color: "#0b9c6b",
                              borderRadius: 15,
                            },
                          },
                          {
                            name: "蛋",
                            value: 10,
                            itemStyle: {
                              color: "#0b9c6b",
                              borderRadius: 15,
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: "nodeBa",
                    value: 20,
                    opacity: 0,
                    children: [
                      {
                        // name: "坚果",
                        value: 30,
                        itemStyle: {
                          color: "#0fa68a",
                          borderRadius: 15,
                        },
                        children: [
                          {
                            name: "奶",
                            value: 50,
                            itemStyle: {
                              color: "#0b9c6b",
                              borderRadius: 15,
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      };
    },
    setTemperature3() {
      this.ChartTemperature3 = {
        legend: {
          data: ["肉类", "动物内脏", "蛋类", "豆类", "牛奶", "水产", "坚果"],
          textStyle: {
            color: "#fff", // 设置图例字体颜色为白色
            fontSize: 16,
          },
          orient: "vertical", // 设置图例的方向为垂直
          right: 1, // 设置图例的位置在右边，距离右边10个像素
          top: 12,
        },
        calculable: true,
        series: [
          {
            name: "漏斗图",
            type: "funnel",
            left: "0",
            top: 20,
            //x2: 80,
            bottom: 60,
            width: "80%",
            // height: "300px",
            min: 0,
            max: 100,
            minSize: "0%",
            maxSize: "100%",
            sort: "descending",
            gap: 2,
            label: {
              normal: {
                show: true,
                position: "inside",
                textStyle: {
                  color: "#fff",
                },
              },
              emphasis: {
                textStyle: {
                  fontSize: 20,
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              normal: {
                length: 10,
                lineStyle: {
                  width: 1,
                  type: "solid",
                },
              },
            },
            itemStyle: {
              normal: {
                borderColor: "#fff",
                borderWidth: 1,
              },
            },
            data: [
              { value: 60, name: "肉类" },
              { value: 30, name: "动物内脏" },
              { value: 20, name: "蛋类" },
              { value: 70, name: "豆类" },
              { value: 10, name: "牛奶" },
              { value: 40, name: "水产" },
              { value: 50, name: "坚果" },
            ],
          },
        ],
      };
    },
    setTemperature4() {
      var legends = ["猕猴桃", "大枣", "柚子", "番荔枝", "木瓜", "龙眼"];
      var colors = [
        "#0278e6",
        "#fabb49",
        "#ee6666",
        "#f19611",
        "#00c6ff",
        "#4cca79",
      ].reverse();
      var data = [
        {
          name: "猕猴桃",
          value: 652,
        },
        {
          name: "大枣",
          value: 297,
        },
        {
          name: "柚子",
          value: 110,
        },
        {
          name: "番荔枝",
          value: 68,
        },
        {
          name: "木瓜",
          value: 50,
        },
        {
          name: "龙眼",
          value: 35,
        },
      ];
      var total = data.reduce((prev, curr) => prev + curr.value, 0);
      this.ChartTemperature4 = {
        backgroundColor: "#0f375f",
        color: colors,
        legend: {
          orient: "vertical",
          top: "center",
          right: "0%",
          itemGap: 12,
          itemWidth: 16,
          itemHeight: 16,
          textStyle: {
            color: "#fff",
            fontSize: 16,
          },
          data: legends,
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        series: [
          {
            name: "VC含量",
            type: "pie",
            radius: ["25%", "55%"],
            center: ["35%", "50%"],
            roseType: "radius",
            minShowLabelAngle: 20,
            label: {
              show: true,
              normal: {
                position: "outside",
                fontSize: 12,
                formatter: (params) => {
                  return (
                    params.name +
                    "(" +
                    ((params.value / total) * 100).toFixed(1) +
                    "%)"
                  );
                },
              },
            },
            labelLine: {
              length: 1,
              length2: 20,
              smooth: true,
            },
            data: data,
          },
        ],
      };
    },
    // 获取主食数据
    getChartData() {
      this.chartList1 = [
        "大米",
        "黑米",
        "燕麦",
        "糙米",
        "小麦",
        "白面条",
        "面粉",
        "馒头",
      ];

      this.setTemperature();
      setInterval(() => {
        if (this.end >= 100) {
          this.start = 0;
          this.end = 60;
        } else {
          this.start = this.start + 10;
          this.end = this.end + 10;
        }

        this.setTemperature();
      }, 2000);
    },
  },
  components: {
    AiHeader,
    Chart,
    foodAnalysis,
    Swiper,
    weather,
    Time
  },
  created() {},
  mounted() {
    this.setTemperature2();
    this.setTemperature3();
    this.setTemperature4();
    // this.setTemperature5();
    this.getChartData();
    document.title = "优食安数字食堂云平台";
  },
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  width: 100%;
  background: url("../assets/image/bg.577ccc4c.jpg");
  .box-left-one,
  .box-left-two,
  .box-left-three,
  .box-left-five,
  .center-bottom {
    position: relative;
    border-radius: 20px;
    padding: 35px 20px;
    border: 1px solid #25a6f3;
    box-shadow: 0px 0px 15px 0px #2adcff inset;
  }

  .box-left-four {
    position: relative;
    border-radius: 5px;
    padding: 20px 30px 10px 30px;
    border: 5px solid #25a6f3;
    box-shadow: 0px 0px 10px 0px #2adcff inset;
  }
  .center-top {
    position: relative;
    border-radius: 5px;
    // padding: 20px 30px 10px 30px;
    box-shadow: 0px 0px 10px 0px #2adcff inset;
  }
  .box-left-one:before,
  .box-left-two:before,
  .box-left-three:before,
  .center-top:before,
  .center-bottom:before,
  .box-left-four:before,
  .box-left-five:before {
    content: attr(data-title);
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #25a6f3;
    color: #fff;
    padding: 0px 30px;
    border-radius: 5px;
    border-bottom: 10px solid #25a6f3;
    padding-top: 5px;
    font-size: 22px;
    font-weight: bolder;
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%);
    z-index: 1;
  }

  .box-left-one {
    .logo {
      display: flex;
      align-items: center;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 20px;
        opacity: 0.8;
      }
      .bg {
        color: white;
        padding-left: 20px;
        margin-top: 5px;
        z-index: 999;
        font-weight: bolder;
        background: rgba(52, 152, 219, 0.3) !important;
        width: 100%;
        height: 100%;
        font-size: 18px;
        .phone{
          margin: 15px 0;
        }
      }
    }
  }
}

/* 中间 */
.box-container {
  min-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding:30px 20px;
  height: calc(100% - 50px);
  > section,
  > aside,
  > article {
    flex: 0 0 25%;
    width: 0;
    > * {
      height: 230px;
      & + * {
        margin-top: 20px;
      }
    }

    .center-top {
      box-sizing: border-box;
      height: 460px;
    }
    .box-left-five {
      height: calc(100% - 440px);
    }
  }
  > section {
    flex: 1;
    margin: 0 20px;
  }
}
</style>
