<template>
  <div>
    <div class="weatherContainer">
      <div class="wheaItem" v-for="(item, index) in weatherList" :key="index">
        <div class="wea">
          <img :src="item.weatherImg" />
        </div>
        <div class="wea">
          <div class="wea-sunny">
            <span>{{ !index ? "今日" : "明日" }} {{ item.weather }}</span>
          </div>
          <span style="margin-top: 5px; display: block;font-size: 18px;">{{
            item.temperature
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCityWeather } from "../api/api";

export default {
  data() {
    return {
      weatherList: [],
    };
  },
  mounted() {
    this.getCityweather();
  },
  methods: {
    // 获取天气
    async getCityweather() {
      const res = await getCityWeather({
        city_id: sessionStorage.getItem("city_id"),
      });
      this.weatherList = res.data.data;
    },
  },
};
</script>

<style scoped lang="scss">
.weatherContainer {
  // width: 300px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bolder;
  color: #c0c4c3;
  .wheaItem {
    display: flex;
    align-items: center;
    margin-left: 25px;
    .wea {
      img {
        width: 40px;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
  }
}
</style>
